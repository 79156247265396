import WebServiceRequest from '../Api/WebServiceRequest'

class SectionCreateRequest extends WebServiceRequest {
  constructor (context) {
    super(context)
    super.setTag('Section/Create')
  }
  setParams(data){
    super.setRequestParamDataObj(data)
  }
}

class SectionUpdateRequest extends WebServiceRequest {
  constructor (context) {
    super(context)
    super.setTag('Section/Update')
  }
  setParams(data){
    super.setRequestParamDataObj(data)
  }
}

class SectionGetRequest extends WebServiceRequest {
  constructor (context) {
    super(context)
  }
  setId(id){
    super.setTag('Section/Get?sectionId='+id)
  }
}

class SectionDeleteRequest extends WebServiceRequest {
  constructor (context) {
    super(context)
  }
  setId(id){
    super.setTag('Section/Delete?id='+id)
  }
}

class SectionGetAllRequest extends WebServiceRequest {
  constructor (context) {
    super(context)
    super.setTag('Section/GetAll')
  }
  setParams(data){
    super.setRequestParamDataObj(data)
  }
}

class SectionGetBySaloonIdRequest extends WebServiceRequest {
  constructor (context) {
    super(context)
  }
  setId(id){
    super.setTag('Section/GetSectionsBySaloonId?saloonId='+id)
  }
}

class SectionGetBySaloonIdAndSeminarIdRequest extends WebServiceRequest {
  constructor (context) {
    super(context)
  }
  setData(data){
    super.setTag('Section/GetSectionsBySaloonIdAndSeminarId?saloonId='+data.saloonId+'&seminarId='+data.seminarId)
  }
}


export {
  SectionCreateRequest,
  SectionUpdateRequest,
  SectionGetRequest,
  SectionDeleteRequest,
  SectionGetAllRequest,
  SectionGetBySaloonIdRequest,
  SectionGetBySaloonIdAndSeminarIdRequest,
}
