import WebServiceRequest from '../Api/WebServiceRequest'

class RowCreateRequest extends WebServiceRequest {
  constructor (context) {
    super(context)
    super.setTag('Row/Create')
  }
  setParams(data){
    super.setRequestParamDataObj(data)
  }
}

class RowUpdateRequest extends WebServiceRequest {
  constructor (context) {
    super(context)
    super.setTag('Row/Update')
  }
  setParams(data){
    super.setRequestParamDataObj(data)
  }
}

class RowGetRequest extends WebServiceRequest {
  constructor (context) {
    super(context)
  }
  setId(id){
    super.setTag('Row/Get?rowId='+id)
  }
}

class RowDeleteRequest extends WebServiceRequest {
  constructor (context) {
    super(context)
  }
  setId(id){
    super.setTag('Row/Delete?id='+id)
  }
}

class RowGetAllRequest extends WebServiceRequest {
  constructor (context) {
    super(context)
    super.setTag('Row/GetAll')
  }
  setParams(data){
    super.setRequestParamDataObj(data)
  }
}

class RowGetAllBySectionIdRequest extends WebServiceRequest {
  constructor (context) {
    super(context)
  }
  setId(id){
    super.setTag('Row/GetAllBySectionId?sectionId='+id)
  }
}

class RowGetAllBySectionIdAndSeminarIdRequest extends WebServiceRequest {
  constructor (context) {
    super(context)
  }
  setData(data){
    super.setTag('Row/GetAllBySectionIdAndSeminarId?sectionId='+data.sectionId+'&seminarId='+data.seminarId)
  }
}

export {
  RowCreateRequest,
  RowUpdateRequest,
  RowGetRequest,
  RowDeleteRequest,
  RowGetAllRequest,
  RowGetAllBySectionIdRequest,
  RowGetAllBySectionIdAndSeminarIdRequest
}
