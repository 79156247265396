import WebServiceRequest from '../Api/WebServiceRequest'

class SaloonGetAllRequest extends WebServiceRequest {
    constructor (context) {
        super(context)
        super.setTag('Saloon/GetAll')
    }
    setParams(data){
        super.setRequestParam(data)
    }
}

export{
    SaloonGetAllRequest,
};